import React from 'react';
import './PopularCourses.css';
import { useNavigate } from 'react-router-dom';
import popularCoursesContent from './PopularCoursesContent';

const PopularCourses = () => {
  const navigate = useNavigate();

  const handleCourseClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className='popular-body'>
      <section className="popular-courses">
        <h2>Popular Courses</h2>
        <div className="popular-courses-list">
          {popularCoursesContent.map((course, index) => (
            <div key={index} className="course-card" onClick={() => handleCourseClick(course.path)}>
              <img src={course.image} alt={course.heading} />
              <div className="course-details">
                <h3>{course.heading}</h3>
                <p>{course.description}</p>

              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default PopularCourses;

import React, { useEffect, useState, useRef } from 'react';
import './Journey.css';
import studentlogo from '../assets/Students.png';
import Professionalslogo from '../assets/Professionals.png';
import Enthusiastslogo from '../assets/Enthusiasts.png';
import switcherslogo from '../assets/Career Switchers.png';
import Researcherslogo from'../assets/Researchers and Academics.png';

const Journey = () => {
  const [bulletPosition, setBulletPosition] = useState(0);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const journeyRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      if (!isComponentVisible) return;

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
      const startScroll = isMobileView ? 0.2 * scrollHeight : 0.130 * scrollHeight;
      const endScroll = isMobileView ? 0.40 * scrollHeight : 0.270 * scrollHeight;

      if (scrollTop >= startScroll && scrollTop <= endScroll) {
        const scrollFraction = (scrollTop - startScroll) / (endScroll - startScroll);
        setBulletPosition(scrollFraction * 100);
      } else if (scrollTop < startScroll) {
        setBulletPosition(0);
      } else if (scrollTop > endScroll) {
        setBulletPosition(100);
      }
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsComponentVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (journeyRef.current) {
      observer.observe(journeyRef.current);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      if (journeyRef.current) {
        observer.unobserve(journeyRef.current);
      }
    };
  }, [isComponentVisible, isMobileView]);

  const getBulletSize = (index) => {
    const scrollPositions = [10, 30, 50, 70, 90,100];

    if (bulletPosition >= scrollPositions[index] && bulletPosition < scrollPositions[index + 1]) {
      return 'moving-bullet-size'; // Apply the size of the moving bullet
    }

    return 'normal-bullet-size'; // Apply the normal size
  };

  return (
    <div className="journey-container" ref={journeyRef}>
      <div className="journey-heading">The Journey</div>
      <div className="journey-line">
        <hr className="journey-blue-line" />
        <div className={`journey-checkpoint ${getBulletSize(0)}`}>
          <div className="checkpoint-bullet"></div>
          <div className="checkpoint-text">Application Process</div>
        </div>
        <div className={`journey-checkpoint ${getBulletSize(1)}`}>
          <div className="checkpoint-bullet"></div>
          <div className="checkpoint-text">Get Trained</div>
        </div>
        <div className={`journey-checkpoint ${getBulletSize(2)}`}>
          <div className="checkpoint-bullet"></div>
          <div className="checkpoint-text">Hands-on Practice</div>
        </div>
        <div className={`journey-checkpoint ${getBulletSize(3)}`}>
          <div className="checkpoint-bullet"></div>
          <div className="checkpoint-text">Resume Enhancement</div>
        </div>
        <div className={`journey-checkpoint ${getBulletSize(4)}`}>
          <div className="checkpoint-bullet"></div>
          <div className="checkpoint-text">Placement Assistance</div>
        </div>
      </div>
      <hr className="journey-divider" />


      <div className="program-for">
        <div className="program-heading">Who is this program for?</div>
        <br />
        <br/>
        <div className="customer-cards">
          <div className="customer-card">
            <div className="left-customer-side">
              <div className="customer-logo"><img src={studentlogo} alt='studentlogo' className='customerlogo' /></div>
            </div>
            <div className="right-customer-side">
              <div className="customer-title">Students</div>
              <div className="customer-description">Those pursuing degrees in computer science, engineering, mathematics, or related fields can enroll to deepen their understanding and acquire practical skills in AI.</div>
            </div>
          </div>

          <div className="customer-card">
            <div className="left-customer-side">
              <div className="customer-logo">
                <img src={Professionalslogo} alt='Professionalslogo' className='customerlogo1' />
              </div>
            </div>
            <div className="right-customer-side">
              <div className="customer-title">Working Professionals</div>
              <div className="customer-description">Individuals in any industry who seek to expand their knowledge and stay updated with the latest AI advancements can benefit from taking an AI course.</div>
            </div>
          </div>

          <div className="customer-card">
            <div className="left-customer-side">
              <div className="customer-logo"><img src={Enthusiastslogo} alt='Enthusiastslogo' className='customerlogo1' /></div>
            </div>
            <div className="right-customer-side">
              <div className="customer-title">Technology Enthusiasts</div>
              <div className="customer-description">Anyone with a keen interest in AI, regardless of their formal background in technology, can enroll to learn the basics, explore applications, and gain a broader understanding of this exciting field.</div>
            </div>
          </div>

          <div className="customer-card">
            <div className="left-customer-side">
              <div className="customer-logo"><img src={switcherslogo} alt='switcherslogo' className='customerlogo' /></div>
            </div>
            <div className="right-customer-side">
              <div className="customer-title">Career switchers</div>
              <div className="customer-description">Individuals aiming to transition their careers into artificial intelligence can leverage AI courses to gain the necessary knowledge and skills for a successful switch.</div>
            </div>
          </div>

          <div className="customer-card">
            <div className="left-customer-side">
              <div className="customer-logo"><img src={Researcherslogo} alt='Researcherslogo' className='customerlogo1' /></div>
            </div>
            <div className="right-customer-side">
              <div className="customer-title">Researchers & Academics</div>
              <div className="customer-description">Professionals in the research and academic communities can utilize AI courses to stay current, explore specialized areas, and enhance their research or teaching capabilities.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Journey;

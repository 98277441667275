import React, { useState, useEffect } from 'react';
import './HeroFace.css';
import heroImage from '../assets/time.png';
import MultiStepForm from './MultiStepForm'; // Import the MultiStepForm component

const HeroFace = () => {
  const [showForm, setShowForm] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleEnrollClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  // Scroll event handler to rotate the image
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        setRotationDegree((prev) => prev + 5); // Adjust speed by changing 5
      } else {
        // Scrolling up
        setRotationDegree((prev) => prev - 5);
      }
      
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // Ensure no negative values
    };

    window.addEventListener('scroll', handleScroll);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <div className="hero-face">
      {!showForm ? (
        <div className="content1">
          <div className="text-container1">
            <div className="Topic1">A platform where learning meets reality</div>
            <div className="Description">
              Whether you're looking to upskill, switch careers, or explore new interests, our diverse course options provide the guidance and support you need to chart your own course to success.
            </div>
            <br />
            <button className='EnButton-white1' onClick={handleEnrollClick}></button>
          </div>
          <div className="image-container-heroface">
            <img
              src={heroImage}
              alt="Hero"
              style={{ transform: `rotate(${rotationDegree}deg)` }} // Apply the rotation
            />
          </div>
        </div>
      ) : (
        <MultiStepForm onClose={handleCloseForm} />
      )}
    </div>
  );
};

export default HeroFace;

import React, { useState } from 'react';
import { dynamoDB } from '../awsConfig'; // Import the configured dynamoDB instance
import './DownloadForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DownloadForm = ({ domainContent, onClose, onDownload }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    department: '',
    domainName: domainContent.topic,
    collegeName: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentDateTime = new Date().toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
    const params = {
      TableName: 'CurriculumDownloads',
      Item: {
        pk: Date.now().toString(),
        ...formData,
        dateTime: currentDateTime // Add date and time field
      }
    };

    try {
      await dynamoDB.put(params).promise();
      toast.success('Data submitted successfully');
      onDownload();
    } catch (err) {
      console.error('Error submitting data:', err);
      toast.error('Error submitting data');
    }
  };

  return (
    <div className="download-form-overlay">
      <div className="download-form-container">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Download Curriculum</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Name:
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Phone Number:
            <input
              type="tel"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Department:
            <input
              type="text"
              name="department"
              placeholder="Department"
              value={formData.department}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            College Name:
            <input
              type="text"
              name="collegeName"
              placeholder="College Name"
              value={formData.collegeName}
              onChange={handleChange}
            />
          </label>
          <div className="button-group">
            <button type="submit">Download</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default DownloadForm;

import React, { useState } from 'react';
import { AppProvider, useAppContext } from './Context';
import './InternOptions.css'; // Import CSS file
import CSandIT from './CSandIT';
import Civil from './Civil';
import Biomed from './Biomed';
import Management from './Management';
import Mechanical from './Mechanical';
import ECEandEEE from './ECEandEEE';
import SkillDevelop from './SkillDevelop';

const InternOptions = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { expandedCourse } = useAppContext();

  const components = [<CSandIT />, <ECEandEEE />, <Civil />, <SkillDevelop />, <Biomed />, <Management />, <Mechanical />];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? components.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === components.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="intern-options">
      <button className="nav-button left" onClick={handlePrev}>
        &#8249; {/* Left arrow symbol */}
      </button>
      
      {components[currentIndex]}
      
      <button className="nav-button right" onClick={handleNext}>
        &#8250; {/* Right arrow symbol */}
      </button>
    </div>
  );
};

export default InternOptions;
